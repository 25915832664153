<template>
  <el-dialog title="问题详情" :width="width" :visible="issueShow" :top = "top" :modal = "modal" :cancel-on-click-modal="false" @close='$emit("update:issueShow", false)'>
    <CustomDetail :issueId="issueId"></CustomDetail>
  </el-dialog>
</template>

<script>
import CustomDetail from '../issue/CustomDetail'
export default {
  name: 'TaskIssueDetail',
  components: { CustomDetail },
  props: {
    issueShow: Boolean,
    issueId: String,
    // 弹框宽度
    width: {
      type: String,
      default: '1000px'
    },
    // 距离顶部位置
    top: {
      type: String,
      default: '15vh'
    },
    // 是否需要遮罩
    modal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
    }
  },
  created () {
    // ....
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
</style>
