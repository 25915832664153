<template>
  <el-dialog title="取消" width="500px" :visible="cancelShow" :cancel-on-click-modal="false" @close='$emit("update:cancelShow", false)'>
    <el-form label-width="80px">
      <el-form-item label="取消人:" prop="bak">
        <p>{{detail.cancelUserName || '--'}}</p>
      </el-form-item>
      <el-form-item label="取消时间:" prop="bak">
        <p>{{detail.cancelTime || '--'}}</p>
      </el-form-item>
      <el-form-item label="取消说明:" prop="bak">
        <p>{{detail.bak || '--'}}</p>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'TaskCancel',
  props: {
    cancelShow: Boolean,
    detail: Object
  },
  data () {
    return {
    }
  },
  created () {
    // ..
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
</style>
