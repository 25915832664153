<template>
  <el-card>
    <div class="title-block">任务信息</div>
    <div class="itemBlock">
      <div class="lineBlock">
        <p class="name">任务名称：</p>
        <p class="value">{{detail.taskName || ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">所属项目：</p>
        <p class="value">{{detail.projectName || ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">巡查方：</p>
        <p class="value">{{[1, 2].includes(detail.patrolUnit) ? patrolUnitList[detail.patrolUnit - 1] : ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">管理类型：</p>
        <p class="value">{{detail.safeManageName || ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">巡查方式：</p>
        <p class="value">{{[1, 2].includes(detail.patrolMode) ? patrolModeList[detail.patrolMode - 1] : ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">巡查类型：</p>
        <p class="value">{{[1, 2, 3].includes(detail.planType) ? planTypeList[detail.planType - 1] : ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">任务执行人：</p>
        <p class="value">{{detail.executeUserName || ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">开始日期：</p>
        <p class="value">{{detail.taskStartTime || ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">结束日期：</p>
        <p class="value">{{detail.taskEndTime || ''}}</p>
      </div>
      <div class="lineBlock">
        <p class="name">完成情况：</p>
        <p class="value" v-if="[1, 2, 3, 4, 5].includes(detail.taskStatus)" :class="{ 'c-dark': [1, 2].includes(detail.taskStatus), 'c-yellow': detail.taskStatus === 3, 'c-green': detail.taskStatus === 4, 'c-red': detail.taskStatus === 5 }">{{taskStatusList[detail.taskStatus - 1]}}</p>
        <p class="value c-gray cancelButton" v-else-if="detail.taskStatus === 6" @click="cancelShow = true">{{taskStatusList[5]}}</p>
      </div>
    </div>
    <div class="line"></div>
    <div class="title-block">巡查过程</div>
    <div class="itemBlock">
      <template v-if="detail.patrolMode === 1">
        <div class="lineBlock1">
          <p class="name">巡查人：</p>
          <p class="value">{{[2, 3, 4].includes(detail.taskStatus) ? detail.executeUserName : '--'}}</p>
        </div>
      </template>
      <template v-else>
        <div class="lineBlock1">
          <p class="name">总点位数：</p>
          <p class="value">{{detail.pointTotal}}</p>
        </div>
        <div class="lineBlock1">
          <p class="name">已巡点位：</p>
          <p class="value">{{detail.pointNum}}</p>
        </div>
      </template>
      <div class="lineBlock1">
        <p class="name">巡查结果：</p>
        <p class="value c-yellow" v-if="detail.issueNum">{{detail.issueNum}}个问题</p>
        <p class="value c-green" v-else-if="[2, 3, 4].includes(detail.taskStatus)">正常</p>
        <p class="value" v-else>--</p>
      </div>
      <div class="lineBlock1">
        <p class="name">巡查开始时间：</p>
        <p class="value">{{detail.patrolStartTime || '--'}}</p>
      </div>
      <div class="lineBlock1">
        <p class="name">巡查结束时间：</p>
        <p class="value">{{detail.patrolEndTime || '--'}}</p>
      </div>
      <div class="lineBlock1">
        <p class="name">巡查时长：</p>
        <p class="value">{{detail.patrolDuration || '--'}}</p>
      </div>
    </div>
    <div class="mapBlock">
      <div class="map" id="map" ref="map"></div>
      <template v-if="detail.patrolMode === 2">
        <div class="numBlock" v-if="detail.pointStatistic">
          <p class="unPatrol"><i></i>未巡查({{detail.pointStatistic.wxcCount || 0}})</p>
          <p class="patrol"><i></i>已巡查({{detail.pointStatistic.yxcCount || 0}})</p>
          <p class="issue"><i></i>问题异常({{detail.pointStatistic.ycCount || 0}})</p>
        </div>
        <div class="drawer" :class="isShowDrawer? 'drawerShow':'drawerHide'">
          <template v-if="drawerData">
            <p class="title">{{drawerData.facilityName || ''}}</p>
            <div class="info">
              <!-- <p class="text">编号：<span>{{drawerData.facilityNo || '--'}}</span></p> -->
              <p class="text">类型：<span>{{drawerData.facilityTypeName || '--'}}</span></p>
              <!-- <p class="text">巡查时间：<span>{{drawerData.patrolTime || '--'}}</span></p> -->
              <p class="text">签到时间：<span>{{drawerData.patrolTime || '--'}}</span></p>
              <div class="detail"><button class="button" style="border: none;" @click="onDetail">查看签到图片</button></div>
            </div>
            <div class="line">
              <el-table class="global-table" v-loading="loading" :data="issueList" max-height="420">
                <el-table-column label="问题描述" prop="issueContent" min-width="100"></el-table-column>
                <el-table-column label="操作" width="60px" fixed="right">
                  <template v-slot="{ row }">
                    <el-button type="text" @click="onShowIssueDetail(row.issueId)">查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </div>
        <div ref="infoWindow">
          <div class="marker-label-content">
            <p class="infoTitle">{{drawerData.facilityName || ''}}</p>
            <div class="info">
              <p class="text">
                类型：
                <span>{{drawerData.facilityTypeName || '--'}}</span>
              </p>
              <template v-if="drawerData.patrolStatus === 1">
                <p class="text">
                  签到时间：
                  <span>{{drawerData.patrolTime || '--'}}</span></p>
                <div class="detail">
                  <div class="button" @click="onDetail">查看签到图片</div>
                </div>
              </template>
            </div>
          </div>
          <div class="marker-label-sharp"></div>
        </div>
      </template>
    </div>
    <CancelDetail :cancelShow.sync="cancelShow" :detail="{
      cancelUserName: detail.cancelUserName,
      cancelTime: detail.cancelTime,
      bak: detail.bak
    }"></CancelDetail>
    <IssueDetail :issueShow.sync="issueShow" :issueId="issueId" ></IssueDetail>
    <ImageViewer :visible.sync="isShowPic" :urlList="pointRegisterImageList"></ImageViewer>
  </el-card>
</template>

<script>
import CancelDetail from './CancelDetail.vue'
import IssueDetail from './IssueDetail.vue'
import ImageViewer from '@/components/ImageViewer'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey, iconUrl } from '../../../utils/config'
import { drawPolygon } from '../../../utils/formatData'
const issue = require('@/assets/img/patrol/issue.svg')
const issueFinished = require('@/assets/img/patrol/issue_finished.svg')
const patrolStart = require('@/assets/img/patrol/start.svg')
const patrolEnd = require('@/assets/img/patrol/end.svg')
const patrolSign = require('@/assets/img/patrol/sign.svg')
const patrolNotpassed = require('@/assets/img/patrol/notpassed.svg')
const patrolPassed = require('@/assets/img/patrol/passed.svg')
const patrolLocation = require('@/assets/img/patrol/location.svg')
export default {
  name: 'TaskDetail',
  components: { CancelDetail, IssueDetail, ImageViewer },
  data () {
    return {
      isShowPic: false,
      pointRegisterImage: null, // 签到点图片对象
      pointRegisterImageList: [], // 传递的签到图片数组
      patrolModeList: ['线路', '点位'],
      planTypeList: ['日巡查', '周巡查', '月巡查'],
      taskStatusList: ['待执行', '执行中', '未完成', '已完成', '已逾期', '已取消'],
      patrolUnitList: ['管理抽查', '项目自查'],
      detail: {
        elementName: '',
        riskLevelName: '',
        standardList: [],
        controlMeasures: '',
        emergencyDisposal: ''
      },
      cancelShow: false,
      markerList: [],
      isShowDrawer: false,
      drawerData: {},
      loading: false,
      issueList: [],
      issueMarkerList: [],
      taskRegisterList: [],
      issueShow: false,
      issueId: ''
    }
  },
  computed: {
    taskId () {
      return this.$route.params.taskId
    }
  },
  watch: {
    taskId () {
      this.clearInfo()
      this.loadData()
    }
  },
  created () {
    this.loadData()
  },
  beforeDestroy () {
    this.clearInfo()
  },
  methods: {
    clearInfo () {
      this._pointTimer && clearInterval(this._pointTimer)
      this._lineTimer && clearInterval(this._lineTimer)
      this._map && this._map.clearMap()
    },
    loadData () {
      this.$axios.get(this.$apis.patrol.selectTaskByTaskId, {
        taskId: this.taskId
      }).then(res => {
        this.detail = res || {}
        this.taskRegisterList = res.taskRegisterList
        this.loadMap(res.patrolMode, res.lineList || [], res.pointList || [])
        // 如果是点位巡查且状态是执行中时，则十秒钟刷新一次
        if (this.detail.patrolMode === 2 && this.detail.taskStatus === 2 && !this._pointTimer) {
          this._pointTimer = setInterval(this.loadData, 10000)
        }
      })
    },
    loadLineList () {
      this.$axios.get(this.$apis.patrol.selectTrackList, {
        taskId: this.taskId
      }).then(res => {
        if (res && res.length) {
          this.drawPolyline(res || [], '#3592FE')
          const markers = [{
            ...res[0],
            type: 'patrolStart'
          }]
          // 只有未完成及已完成才有终点，执行中没有
          if ([3, 4].includes(this.detail.taskStatus)) {
            markers.push({
              ...res[res.length - 1],
              type: 'patrolEnd'
            })
          } else {
            // 执行中实时更新点位，一秒获取一次最新点位
            this._lastLinePointer = { ...res[res.length - 1] }
            if (!this._lineTimer) {
              this._lineTimer = setInterval(this.loadNewLineList, 1000)
            }
            const lngLat = new AMap.LngLat(this._lastLinePointer.longitude, this._lastLinePointer.latitude)
            const marker = new AMap.Marker({
              icon: new AMap.Icon({
                // 图标的取图地址
                image: patrolLocation
              }),
              size: new AMap.Pixel(36, 48),
              offset: new AMap.Pixel(-18, -48),
              position: lngLat
            })
            this._map.add(marker)
            this._locationMarker = marker
          }
          this.drawMarkers(markers, 'patrol')
        }
      })
    },
    loadNewLineList () {
      this.$axios.get(this.$apis.patrol.selectNewTrack, {
        taskId: this.taskId
      }).then(res => {
        if (res && res.trackId !== this._lastLinePointer.trankId && res.latitude && res.longitude) {
          this.drawPolyline([this._lastLinePointer, res], '#3592FE')
          this._lastLinePointer = { ...res }
          this._locationMarker.setPosition(new AMap.LngLat(res.longitude, res.latitude))
        }
      })
    },
    loadIssueList (facilityId) {
      this.loading = true
      this.$axios.get(this.$apis.patrol.selectIssueByList, {
        taskId: this.detail.taskId,
        facilityId
      }).then(res => {
        this.issueList = res || []
        if (this.detail.patrolMode === 1) {
          this.drawMarkers(this.issueList, 'issue')
        }
      }).finally(() => {
        this.loading = false
      })
    },
    onDetail () {
      this.pointRegisterImage = this.drawerData.pointRegisterImage
      //   console.log('this.drawerData', this.drawerData)
      this.pointRegisterImageListHandler()
      this.isShowPic = true
    },
    pointRegisterImageListHandler () {
      const obj = {}
      obj.name = this.pointRegisterImage && this.pointRegisterImage.fileName
      obj.url = this.pointRegisterImage && this.pointRegisterImage.filePrefix + this.pointRegisterImage.fileUrl
      this.pointRegisterImageList = [obj]
    },
    loadMap (patrolMode, lineList, pointList) {
      let center = [118.709695, 32.019756]
      if (lineList.length) {
        center = [Number(lineList[0].longitude), Number(lineList[0].latitude)]
      } else if (pointList.length) {
        center = [Number(pointList[0].longitude), Number(pointList[0].latitude)]
      }
      if (!this._map) {
        AMapLoader.load({
          key: mapKey,
          version: '2.0',
          plugins: []
        }).then(() => {
          const map = new AMap.Map('map', {
            center: center,
            zoom: 16
          })
          this._map = map
          this._map.on('click', this.mapClick)
          this.mapInit(patrolMode, lineList, pointList)
        })
      } else {
        this._map.clearMap()
        this._map.setZoomAndCenter(16, center)
        this.mapInit(patrolMode, lineList, pointList)
      }
    },
    mapInit (patrolMode, lineList, pointList) {
      if (patrolMode === 1) {
        // 只有执行中,未完成及已完成才有巡查线路
        if ([2, 3, 4].includes(this.detail.taskStatus)) {
          this.loadLineList()
        }
        this.loadIssueList()
        this.drawPolyline(lineList || [], '#FF9B00', true)
        if (lineList && lineList.length) {
          const markers = [{
            ...lineList[0],
            type: 'patrolSign'
          }, {
            ...lineList[lineList.length - 1],
            type: 'patrolSign'
          }]
          this.drawMarkers(markers, 'patrol')
          this.drawMustPoints(this.taskRegisterList)
        }
      } else {
        this.drawPointMarkers(pointList || [])
      }
    },
    drawPolyline (lineList, color, isSetView) {
      // 巡查路线
      const lngLat = lineList.map(item => {
        return new AMap.LngLat(item.longitude, item.latitude)
      })
      // 创建面对象
      const polyline = new AMap.Polyline({
        path: lngLat,
        strokeColor: color,
        strokeOpacity: 1,
        strokeWeight: 4
      })
      this._map && this._map.add(polyline)
      isSetView && this._map.setFitView(polyline)
    },
    getIcon (type, status) {
      let icon = null
      let size = new AMap.Pixel(24, 24)
      let offset = new AMap.Pixel(-12, -12)
      let iconUrl = ''
      switch (type) {
        case 'issue':
          iconUrl = [1, 2].includes(status) ? issue : issueFinished
          icon = new AMap.Icon({
            image: iconUrl
          })
          size = new AMap.Pixel(36, 48)
          offset = new AMap.Pixel(-18, -48)
          break
        default:
          // patrol
          iconUrl = status === 'patrolStart' ? patrolStart : status === 'patrolEnd' ? patrolEnd : patrolSign
          icon = new AMap.Icon({
            image: iconUrl
          })
          break
      }
      return { icon, size, offset }
    },
    drawMarkers (list, type) {
      list.forEach(item => {
        const iconInfo = this.getIcon(type, item.issueStatus || item.type)
        const lngLat = new AMap.LngLat(Number(item.longitude), Number(item.latitude))
        const marker = new AMap.Marker({
          icon: iconInfo.icon,
          size: iconInfo.size,
          offset: iconInfo.offset,
          position: lngLat,
          extData: { issueId: item.issueId || '' }
        })
        if (type === 'issue') {
          marker.on('click', this.issueMarkerClick)
          this.issueMarkerList.push(marker)
        }
        this._map.add(marker)
      })
    },
    drawMustPoints (list) {
      list.forEach(item => {
        const lngLat = new AMap.LngLat(Number(item.longitude), Number(item.latitude))
        const iconUrl = item.registerStatus === 1 ? patrolNotpassed : patrolPassed
        const marker = new AMap.Marker({
          icon: new AMap.Icon({
            // 图标的取图地址
            image: iconUrl
          }),
          size: new AMap.Pixel(44, 49),
          offset: new AMap.Pixel(-22, -38),
          position: lngLat,
          label: {
            direction: 'top',
            content: '必经点'
          }
        })
        this._map.add(marker)
      })
    },
    onClose () {
    //   this.isShowPic = false
      this.$emit('update: isShow', false)
    },
    drawPointMarkers (pointList) {
      // 如果巡查点位已经打点，则先清除
      this._mpointMarkers && this._mpointMarkers.forEach(marker => {
        this._map.remove(marker)
      })
      this._mpointMarkers = []
      pointList.forEach(item => {
        const lngLat = new AMap.LngLat(Number(item.longitude), Number(item.latitude))
        const marker = new AMap.Marker({
          icon: new AMap.Icon({
            // 图标的取图地址
            image: iconUrl + item.facilityIcon + '_' + item.patrolStatus + '.png'
          }),
          size: new AMap.Pixel(30, 30),
          offset: new AMap.Pixel(-15, -27),
          position: lngLat,
          extData: { ...item }
        })
        if (item.patrolStatus === 2) {
          this.markerList.push(marker)
        }
        marker.on('click', this.markerClick)
        this._map.add(marker)
        this._mpointMarkers.push(marker)
      })
      this._map.setFitView(this._map.getAllOverlays('marker'))
    },
    mapClick () {
      if (this.isShowDrawer) {
        this.isShowDrawer = false
        this.drawerData = {}
      }
      this._map.clearInfoWindow()
    },
    issueMarkerClick (e) {
      this.onShowIssueDetail(e.target.getExtData().issueId)
    },
    markerClick (e) {
      this.mapClick()
      this.drawerData = e.target.getExtData()
      if (this.drawerData.patrolStatus === 2) {
        this.isShowDrawer = true
        this.loadIssueList(this.drawerData.facilityId)
      } else {
        this.$nextTick(() => {
          const infoWindow = new AMap.InfoWindow({
            content: this.$refs.infoWindow,
            anchor: 'bottom-center',
            offset: new AMap.Pixel(15, -30),
            isCustom: true
          })
          infoWindow.open(this._map, [this.drawerData.longitude, this.drawerData.latitude])
        })
      }
    },
    onShowIssueDetail (issueId) {
      this.issueId = issueId
      this.issueShow = true
    }
  }
}
</script>

<style scoped lang="scss">
  .cancelButton {
    text-decoration: underline;
    cursor: pointer;
  }
  .title-block {
    width: 100%;
    height: 14px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 14px;
    margin-bottom: 20px;
  }
  .line {
    border-top: 1px solid #EAEEF5;
    margin-bottom: 20px;
  }
  .lineBlock {
    display: flex;
    margin-bottom: 20px;
    p {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
      &.name {
        width: 100px;
        flex-shrink: 0;
        text-align: right;
        color: #333333;
      }
    }
  }
  .itemBlock {
    display: flex;
    flex-wrap: wrap;
    .lineBlock {
      width: 400px;
      margin-right: 40px;
    }
    .lineBlock1 {
      margin-bottom: 20px;
      display: flex;
      width: 300px;
      margin-right: 20px;
      p {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #666666;
      &.name {
        width: 100px;
        flex-shrink: 0;
        text-align: right;
        color: #333333;
      }
    }
    }
  }
  .mapBlock {
    position: relative;
    width: 100%;
    height: 670px;
    .map {
      width: 100%;
      height: 100%;
      z-index: 1;

    }
    .numBlock {
      position: absolute;
      top: 20px;
      left: 20px;
      display: flex;
      align-items: center;
      width: auto;
      height: 40px;
      padding: 0 16px;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.05);
      border-radius: 2px;
      z-index: 2;
      p {
        &:not(:last-child) {
          margin-right: 20px;
        }
        i {
          display: inline-block;
          width: 14px;
          height: 14px;
          background: rgba(148,148,148,0.3);
          border: 1px solid #949494;
          border-radius: 50%;
          margin-right: 5px;
        }
        &.patrol {
          i {
            border-color: #52C41A;
            background: rgba(82, 196, 26, 0.30);
          }
        }
        &.issue {
          i {
            border-color: #D0021B;
            background: rgba(208, 2, 27, 0.30);
          }
        }
      }
    }
    .drawer{
      position: absolute;
      right: -420px;
      top: 20px;
      z-index: 20;
      width: 390px;
      height: calc(100% - 40px);
      padding: 16px;
      background: #FFFFFF;
      box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      border-left: 4px solid #1AD1B0;
      transition: all 0.4s;
      overflow: auto;
      .title {
        width: auto;
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 22px;
        margin-bottom: 15px;
      }
      .info{
        padding-bottom: 20px;
        .text{
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          &:not(:last-child) {
            padding-bottom: 10px;
          }
          span {
            color: #666666;
          }
        }
        .detail {
            width: 100%;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            .button {
                user-select: none;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 116px;
                height: 32px;
                background: #1AD1B0;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 22px;
                }
        }
      }
      .line {
        border-top: 1px solid #F1F1F1;
        padding-top: 20px;
      }
    }
    .drawerShow{
      transform:translateX(-440px)
    }
    .drawerHide{
      transform:translateX(0px)
    }
  }
  ::v-deep {
    .amap-info-contentContainer{
      border:none;
      padding:0;
      background: transparent;
      .marker-label-content{
        position: relative;
        width: auto;
        height: auto;
        min-width: 238px;
        min-height: 102px;
        box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.1);
        margin-bottom: 5px;
        background: #ffffff;
        .infoTitle {
            margin: 0;
            padding: 12px 20px;
            width: 100%;
            height: 44px;
            background-color: #F4F7FA;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 600;
            color: rgba(0,0,0,0.85);
            line-height: 20px;
        }
        .info {
            padding: 20px;
            .text {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
              span {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
              }
              &:not(:last-child) {
                margin-bottom: 18px;
              }
            }
            .detail {
              width: 100%;
              height: 32px;
              display: flex;
              justify-content: center;
              .button {
                user-select: none;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 116px;
                height: 32px;
                background: #1AD1B0;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                line-height: 22px;
              }
            }
        }
      }
      .marker-label-sharp {
        position: absolute;
        bottom: 0;
        left: 50%;
        border-top: 8px solid #ffffff;
        &:after {
          position: absolute;
          content: "";
          margin-top: -7px;
          border-top: 8px solid rgba(0,0,0,.3);
          filter: blur(2px);
          z-index: -1;
        }
        &, &:after {
          margin-left: -8px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
        }
      }
    }
  }
</style>
